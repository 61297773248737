import React, { ReactElement } from "react"
import { Link } from "gatsby"

interface IDropDownItem {
  title: string
  link?: string
}

interface Props {
  title: string
  items?: IDropDownItem[]
  className?: string
}

function DropDownMenu({ title, items, className }: Props): ReactElement {
  const [visible, setVisible] = React.useState(false)

  const onMouseOver = (e: any) => {
    setVisible(true)
  }
  const onMouseOut = (e: any) => {
    setVisible(false)
  }
  return (
    <div
      className={"relative flex  justify-center p-2 flex-shrink-0 " + className}
    >
      <Link to={"#"}>
        <div onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
          {title}
        </div>
      </Link>
      <div className="p-1">
        <img src={require("../../images/list-arrow.svg").default} />
      </div>
      {items && items.length ? (
        <div
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          className={
            "absolute z-10 mt-2 w-full pt-4  right-0 " + (visible ? "" : "hidden")
          }
        >
          <div className="py-2 shadow-md bg-gray-100 rounded ">
            {items.map((item: IDropDownItem) => (
              <Link to={item.link as any}>
                <div className="py-2 px-4 cursor-pointer hover:bg-gray-300">
                  {item.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default DropDownMenu
