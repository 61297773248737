import React, { ReactElement } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface Props {
  pageTitle?: string
}

function HelmetContainer({ pageTitle }: Props): ReactElement {
  const { allSite } = useStaticQuery(
    graphql`
      query {
        allSite {
          nodes {
            siteMetadata {
              title
              description
            }
          }
        }
      }
    `
  )

  const { title, description } = allSite.nodes[0].siteMetadata

  return (
    <div>
      <Helmet title={title + " :: " + pageTitle}>
        <meta name="description" content={description} />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-134361704-5"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-134361704-5');`}
        </script>
      </Helmet>
    </div>
  )
}

export default HelmetContainer
