import React, { ReactElement } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

function Footer({}: any): ReactElement {
  const { allSite } = useStaticQuery(
    graphql`
      query {
        allSite {
          nodes {
            siteMetadata {
              footerMenu {
                title
                link
              }
              logo
              title
              domain
              disclaimer {
                title
                text
              }
            }
          }
        }
      }
    `
  )

  const menu = allSite.nodes[0].siteMetadata.footerMenu
  const { title, domain, disclaimer } = allSite.nodes[0].siteMetadata
  const dt = new Date()
  const dateRange = dt.getFullYear() - 5 + " - " + dt.getFullYear()
  return (
    <>
      <div className="bg-blue-900 w-screen flex flex-col p-5">
        <div className="w-full md:w-3/4 mx-auto">
          <div className="flex justify-center items-center flex-col mt-8">
            <img className="w-24" src={require('../../images/logo-small.svg').default} />
            {/* <div className="text-gray-400 text-sm">{title}</div> */}
          </div>
          <div className="flex justify-center my-4 flex-wrap text-white">
            {menu.map((mi: any,index:number) => (
              <Link
              key={index}
                to={mi.link}
                className="m-2 text-blue-200 hover:text-blue-100"
              >
                <div className=" p-2">{mi.title}</div>
              </Link>
            ))}
          </div>
          <div className="flex flex-col items-center mb-8 text-gray-200">
            <div className="text-lg ">{disclaimer.title}</div>
            <div className=" text-xs text-justify">{disclaimer.text}</div>
          </div>
        </div>

        <div className="text-center text-xs text-blue-700">
          Copyrights @ {title} - {dateRange}
        </div>
      </div>
    </>
  )
}

export default Footer
