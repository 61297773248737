import React from "react"
import Footer from "../../hoc/footer"
import Header from "../../hoc/header"
const DefaultLayout = ({ children }: any) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  )
}
export default DefaultLayout
