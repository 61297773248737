import React, { ReactElement } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import DropDownMenu from "../../components/drop-down"

function Header({}: any): ReactElement {
  const { allSite } = useStaticQuery(
    graphql`
      query {
        allSite {
          nodes {
            siteMetadata {
              homeLink
              mainMenu {
                title
                link
                subMenu {
                  title
                  link
                }
              }
            }
          }
        }
      }
    `
  )

  const [visible, setVisible] = React.useState(false)

  const onMenuOpen = (e: any) => {
    setVisible(!visible)
  }

  const menu = allSite.nodes[0].siteMetadata.mainMenu
  const homeLink = allSite.nodes[0].siteMetadata.homeLink
  return (
    <>
      <div className="absolute w-screen flex flex-row p-5">
        <div className="flex justify-start flex-shrink-0 flex-grow-0">
          <Link to={homeLink || "/"}>
            <img className="w-64" src={require("../../images/logo.svg").default} />
          </Link>
        </div>
        <div className="hidden md:flex flex-1 flex-row flex-shrink-0 items-center text-sm justify-end">
          {menu &&
            menu.map((item: any, index: number) => (
              <>
                {item.subMenu ? (
                  <DropDownMenu
                    className="ml-5"
                    title={item.title}
                    items={item.subMenu}
                  />
                ) : (
                  <div
                    key={index}
                    className=" border-gray-700 p-2 ml-5 flex-shrink-0"
                  >
                    <Link to={item.link}>{item.title}</Link>
                  </div>
                )}
              </>
            ))}
        </div>
        <div className="md:hidden w-full flex justify-end">
          <img
            onClick={onMenuOpen}
            className="w-6 cursor-pointer"
            src={require("../../images/open-menu.svg").default}
          />
        </div>
      </div>
      <div
        className={
          "pt-20 rounded-md shadow-lg " + (visible ? " flex-row" : "hidden")
        }
      >
        {menu && menu.length
          ? menu.map((item: any) => (
              <>
                {item.subMenu && item.subMenu.length ? (
                  item.subMenu.map((sitem: any) => (
                    <Link to={sitem.link}>
                      <div
                        style={{ borderBottom: "1px solid #F1F1F1" }}
                        className="px-4 py-4 cursor-pointer hover:bg-gray-200"
                      >
                        {sitem.title}
                      </div>
                    </Link>
                  ))
                ) : (
                  <Link to={item.link}>
                    <div
                      style={{ borderBottom: "1px solid #F1F1F1" }}
                      className="px-4 py-4 cursor-pointer hover:bg-gray-200"
                    >
                      {item.title}
                    </div>
                  </Link>
                )}
              </>
            ))
          : ""}
      </div>
    </>
  )
}

export default Header
